import properties from "./Properties";
import { useEffect } from "react";

const FormBody = ({ fetchError, walletPaymentResponse, sign, msg }) => {
  useEffect(() => {
    if (fetchError) {
      const formBody = {
        sourcePayNumber: walletPaymentResponse.sourcePayNumber,
        paymentStatus: msg,
        returnMessage:
          msg === properties.CLIENT_APP_INVALID_PAYMENT_STATUS
            ? properties.CLIENT_APP_INVALID_RETURN_MESSAGE
            : properties.INTERNAL_FAIL_RETURN_MESSAGE,
        returnCode:
          msg === properties.CLIENT_APP_INVALID_PAYMENT_STATUS
            ? properties.CLIENT_APP_INVALID_RETURN_CODE
            : properties.INTERNAL_FAIL_RETURN_CODE,
        sign: sign,
      };

      const inputs = document.getElementById("form-submit").childNodes;
      inputs.forEach((entry) => {
        entry.value =
          formBody[entry.name] === undefined ? null : formBody[entry.name];
      });
      document.getElementById("form-submit").submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="form-hidden" style={{ display: "none" }}>
      <form
        id="form-submit"
        action={walletPaymentResponse.notifyUrl}
        method={properties.POST}
      >
        <input type="text" name="sourcePayNumber" />
        <input type="text" name="paymentStatus" />
        <input type="text" name="returnCode" />
        <input type="text" name="returnMessage" />
        <input type="text" name="sign" />
        <input type="submit" className="hidden-submit" value="Submit" />
      </form>
    </div>
  );
};

export default FormBody;
