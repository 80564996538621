import React from "react";
import { useState, useEffect } from "react";
import SockJsClient from "react-stomp";
import properties from "./Properties.js";
import Countdown from "react-countdown";
import { env } from "../env";

const WebSocketConnectionComponent = (props) => {
  let clientRef = useState();

  const getExpiryTime = () => {
    return (
      new Date(props.msg.payEndtime.replace(" ", "T")) -
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: "Asia/Shanghai",
        })
      )
    );
  };

  const [currentTimeStamp] = useState(Date.now());
  const expiryTime = getExpiryTime() + 3000;
  const dateCounter = new Date(currentTimeStamp + expiryTime);
  const timerRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      const msg = {
        sourceOrderNumber: props.msg.sourceOrderNumber,
        sourcePayNumber: props.msg.sourcePayNumber,
        status: properties.TIMEOUT,
        paymentAmount: props.msg.totalPayment,
      };
      clientRef.sendMessage(
        "/queue/" + props.msg.sourcePayNumber,
        JSON.stringify(msg)
      );
      return <span>{`00 minutes 00 seconds`}</span>;
    } else {
      return <span>{`${minutes} minutes ${seconds} seconds`}</span>;
    }
  };

  const onCancelHandler = () => {
    const msg = {
      sourceOrderNumber: props.msg.sourceOrderNumber,
      sourcePayNumber: props.msg.sourcePayNumber,
      status: properties.CANCELLED,
      paymentAmount: props.msg.totalPayment,
    };
    sendMessage(msg);
  };

  const onConnected = () => {
    setTimeout(() => {
      let currentDuration = parseInt(env.REACT_APP_WAITING_TIME);
      callOrderState();
      const interval = setInterval(() => {
        currentDuration += parseInt(env.REACT_APP_RETRY_TIMEOUT);
        currentDuration >= expiryTime
          ? clearInterval(interval)
          : callOrderState();
      }, parseInt(env.REACT_APP_RETRY_TIMEOUT));
    }, parseInt(env.REACT_APP_WAITING_TIME));
  };

  const callOrderState = () => {
    const msg = {
      sourceOrderNumber: props.msg.sourceOrderNumber,
      sourcePayNumber: props.msg.sourcePayNumber,
      status: properties.UNPAID,
      paymentAmount: props.msg.totalPayment,
    };
    sendMessage(msg);
  };

  const onMessageReceived = (msg) => {
    if (msg.paymentStatus != null && msg.paymentStatus !== `UNPAID`) {
      const inputs = document.getElementById("form-submit").childNodes;
      inputs.forEach((entry) => {
        entry.value = msg[entry.name] === undefined ? null : msg[entry.name];
      });
      document.getElementById("form-submit").submit();
      clientRef.disconnect();
    }
  };

  const sendMessage = (msg) => {
    clientRef.sendMessage(
      properties.QUEUE + props.msg.sourcePayNumber,
      JSON.stringify(msg)
    );
  };

  useEffect(() => {
    //appending QR CODE DIV
    const qrCodePlacementDiv = document.getElementById("qrCodePlacementDiv");
    document.getElementById("qrCodeDiv").appendChild(qrCodePlacementDiv);

    //appending TIMER DIV
    const timer = document.getElementById("timer");
    document.getElementsByClassName("count-down")[0].appendChild(timer);
    const cancelLinks = document.querySelectorAll(".cancel-payment-link");
    cancelLinks.forEach((linkObject) => {
      linkObject.addEventListener("click", () => {
        onCancelHandler();
      });
    });
    //Ali Pay LOGO
    const aliPayLogo = document.getElementsByClassName("logo");
    aliPayLogo[0].src = env.REACT_APP_ALIPAY_LOGO;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SockJsClient
        url={env.REACT_APP_WALLET_PAYMENT_WEBSOCKET_URL}
        topics={[properties.QUEUE + props.msg.sourcePayNumber]}
        onConnect={onConnected}
        ref={(client) => {
          clientRef = client;
        }}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={false}
      />

      <div id="timer" style={{ fontWeight: "bold" }}>
        <Countdown date={dateCounter} renderer={timerRenderer} />
      </div>
    </div>
  );
};

export default WebSocketConnectionComponent;
