import InnerHTML from "dangerously-set-html-content";
import QRCode from "react-qr-code";
import WebSocketConnectionComponent from "./WebSocketConnectionComponent";
import FormBody from "./FormBody";

const Response = ({ msg, walletPaymentResponse, fetchError, sign }) => {
  return (
    <div>
      {!fetchError && (
        <>
          <InnerHTML html={msg} />
          <div id="qrCodePlacementDiv">
            <QRCode size={200} value={walletPaymentResponse.qrCode} />
          </div>
          <WebSocketConnectionComponent msg={walletPaymentResponse} />
        </>
      )}
      <FormBody
        fetchError={fetchError}
        walletPaymentResponse={walletPaymentResponse}
        sign={sign}
        msg={msg}
      />
    </div>
  );
};

export default Response;
