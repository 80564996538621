const properties = {
  CANCELLED: "Cancelled",
  QUEUE: "/queue/",
  TIMEOUT: "Timeout",
  UNPAID: "Unpaid",
  POST: "POST",
  GET: "GET",
  INTERNAL_FAIL_PAYMENT_STATUS: "INTERNAL_FAIL",
  INTERNAL_FAIL_RETURN_MESSAGE: "Internal Fail.",
  INTERNAL_FAIL_RETURN_CODE: "307",
  CLIENT_APP_INVALID_PAYMENT_STATUS: "CLIENT_APP_INVALID",
  CLIENT_APP_INVALID_RETURN_MESSAGE: "The provided client_app_id is invalid.",
  CLIENT_APP_INVALID_RETURN_CODE: "101",
};

export default properties;
